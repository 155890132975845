import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import ALink from "../../components/a-link/a-link";
import Hero from "../../components/hero/hero";
import Logo from "../../images/svg/aplinet_logo_text.inline.svg";

import ServiceIconWebsite from "../../images/offer/service_icon_website.svg";
import ServiceIconECommerce from "../../images/offer/service_icon_e-commerce.svg";
import ServiceIconMobile from "../../images/offer/service_icon_mobile.svg";
import ServiceIconDesign from "../../images/offer/service_icon_design.svg";
import ServiceIconDesktop from "../../images/offer/service_icon_desktop.svg";
import ServiceIconSoftware from "../../images/offer/service_icon_software.svg";
import ServiceIconConsulting from "../../images/offer/service_icon_consulting.svg";
import ServiceIconInfrastructure from "../../images/offer/service_icon_infrastructure.svg";

import "./offer-item.scss";
import HeaderBig from "../../components/header-big/header-big";
import StickyContainer from '../../components/sticky/sticky-container';

class OfertaItem extends React.Component {
  language = "pl";
  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
  }

  render() {
    return (
      <>
        <SEO title="Oferta" />
        <Hero>
          <div className="container-xs header-padding">
            <h1>Strony internetowe </h1>
            <div className="header-subline">
              <h2 className="subtitle">Responsywne strony internetowe, SEO</h2>
            </div>
            <h4>
              Oferujemy wykonanie strony www, internetowej, od koncepcji,
              projektu graficznego do zaprogramowania wraz z&nbsp;kompleksową
              obsługą w&nbsp;zakresie pozycjonowania SEO oraz kampanii AdWords.
              Sprawdź, jak wysoko nasza agencja interaktywna znajduje się
              w&nbsp;wynikach Google pod kątem słów kluczowych związanych
              z&nbsp;profilem naszej działalności.
            </h4>
          </div>
        </Hero>
        <div className="">
          <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
            
            <div className="home-list-sticky-wrapper-nazwa-z-dupy">
              <ul>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="01"
                      headerText="Tworzenie założeń projektów. Ocaena i&nbsp;plan koncepcji."
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="02"
                      headerText="Management - wsparcie i&nbsp;prowadzenie projektów."
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="03"
                      headerText="Identyfikacja wizualna i&nbsp;projektowanie Graficzne"
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="04"
                      headerText="Web development"
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="05"
                      headerText="Machine Learning"
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="06"
                      headerText="Mobile development"
                    />
                  </div>
                </li>
                <li>
                  <div className="item">
                    <HeaderBig
                      tag="h3"
                      span="07"
                      headerText="Startup's i&nbsp;Projekty EU"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div data-sticky className="container">
              <HeaderBig
                tag="h2"
                span="#Eksperci"
                headerText="Posiadamy doświadczenie w&nbsp;realizacji cyfrowych projektów"
              />
              <p>
                Wspieramy naszych klientów od wczesnego etapu analizy
                i&nbsp;planowania działań, przez kompleksową realizację, po
                ocenę i&nbsp;audyt gotowego oprogramowania.
              </p>
              <p>
                Korzystamy z&nbsp;technologii JavaScript oraz projektujemy
                najlepsze UX i&nbsp;UI zgodnie z&nbsp;potrzebami naszych
                klientów, umożliwiając sprawną realizację pomysłu.
              </p>
              <ALink to="/o-nas/" language={this.language}>
                Poznaj nas
              </ALink>
              lub
              <ALink to="/o-nas/" language={this.language}>
                Przejdź na stronę oferty
              </ALink>
            </div>
          </StickyContainer>
        </div>
      </>
    );
  }
}

export default OfertaItem;
